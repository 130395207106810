.order-sec,
.confirm-payment {
    background-color: #e6e6e6;
    line-height: inherit;
    padding: 2rem 0;

    .page-container {
        max-width: 480px;
        background: #fff;
        margin: auto;
        box-shadow: 0px 1px 2px #efefef !important;
        border-radius: 4px;
    }

    p {
        margin-bottom: 0;
    }

    .error {
        color: red;
        font-size: 0.9em;
    }

    label {
        margin-bottom: 10px;
        font-weight: 500;
        font-family: Inter_Med;
    }

    input,
    select {
        box-shadow: 0px 1px 2px #efefef !important;
        padding: 7px;
        border-radius: 0.25rem;

        &::placeholder {
            color: #C0B6BA;
        }
    }

    input,
    select,
    button:focus {
        box-shadow: none;
        outline: 0;
    }

    .form-control {
        height: 39px !important;
    }


    .submit-btn {
        width: 100%;
        border: 0;
        background-color: #000;
        color: #fff;
        border-radius: 0.25em;
        transition: 0.5s ease-in-out all;

        &:hover {
            opacity: 0.8;
        }
    }

    .card-input {
        .removeIcon {
            top: 39px;
        }

        .form-control {
            padding-top: 11px;
            z-index: 99;
        }
    }

    .add-checkbox {

        label {
            font-size: 13px;
            white-space: pre;
            margin-bottom: 0;
        }

        input {
            cursor: pointer;
            float: unset;
            margin-top: 6px;
            margin-right: 6px;
        }
    }

    .cp_variant-quantity-wrapper {
        img {
            opacity: 0.7;

            &:hover {
                opacity: 1;
            }
        }
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    .cp_variant-option {
        box-shadow: 0px 1px 2px #efefef !important;
    }

    .removeIcon {
        position: absolute;
        right: 6px;
        top: 6px;
        cursor: pointer;

        img {
            width: 14px;
        }
    }

    .location-in_icon {
        text-overflow: ellipsis;
        padding-right: 27px;
    }
}

// confirmation screen 
.confirm-payment {
    background-color: #f5f5f5;
    height: 100vh;

    .page-container {
        max-width: 500px;
    }

    .cp_status-title {
        font-size: 1.25rem;
    }

    .cp_status-text {
        color: #503e43;
        font-size: 16px;

        p {
            margin-bottom: 1rem;
        }
    }

}

.req-box {

    input,
    .flag-dropdown {
        border-color: red !important;
    }
}