.modalhome {
    .bodyModal {
        padding: 40px 30px;
        background: #bc9471;
        border-radius: 4px;
        min-height: 395px;
    }

    h3 {
        font-family: NotoSerifDisplay_Med;
        font-size: 24px;
        text-align: center;
        color: #623e2a;
    }

    .submitted-modal {
        height: 242px;

        img {

            animation: zoominoutsinglefeatured 1s;

        }
    }

}

.modal-spinner {
    background: #000;
    width: 100%;
    height: 100%;
    border-radius: 6px;
}