@font-face {
    font-family: Inter_Reg;
    src: url("../assets/fonts/Inter-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: Inter_Med;
    src: url("../assets/fonts/Inter-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: GothicA1_Med;
    src: url("../assets/fonts/GothicA1-Medium.ttf");
    font-weight: 500;
}

@font-face {
    font-family: NotoSerifDisplay_Med;
    src: url("../assets/fonts/NotoSerifDisplay-Medium.ttf");
    font-weight: 500;
}