.inputFill,
.inputFill:focus,
.form-control.inputFill {
    padding: 15px;
    border-radius: 8px;
    background: #917256;
    color: #fff;
    font-size: 16px;
    border: 1px solid #917256;
    width: 100%;
    outline: none;
    box-shadow: none;
    min-height: 55px;

    &::placeholder {
        color: #fff;
    }
}

.ph-drop {
    border: 0 !important;
    border-right: 1px solid #c09474 !important;
    background: #917256 !important;

    .open.selected-flag {
        background: #917256 !important;
    }


    .selected-flag:hover,
    .selected-flag:focus {
        background: #917256;
    }
}

.btnCus {
    color: #000;
    background-color: #fff;
    min-width: 143px;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    transition: ease-in-out 0.5s;
    font-family: Inter_Med;

    &:hover {
        color: #fff;
        background-color: #000;
    }

    &:active {
        scale: 1.2;
    }

    &.modalbtn {
        color: #fff;
        background-color: #000;
        width: 100%;
        padding: 16px;
        border-radius: 8px;
    }
}

input {

    &:disabled {
        pointer-events: inherit !important;
        cursor: not-allowed !important;

        ~label {
            pointer-events: inherit !important;
            cursor: not-allowed !important;

        }
    }
}

.add-checkbox {
    gap: 32px;

    label {
        padding-top: 5px;
    }
}