body {
    font-family: Inter_Reg;
    font-size: 16px;
    background: #bc9471;
}

.Inter_Med {
    font-family: Inter_Med;
}

.containerCus {
    max-width: 1440px;
    margin: auto;
    padding: 0 50px;

    @media (max-width: 767px) {
        padding: 0 15px;
    }
}

.mainBanner {
    background: #bc9471;
    min-height: 100vh;
    height: 100%;
    width: 100%;
    padding-bottom: 0;
}

.mainBanner_content {
    max-width: 590px;

    @media (max-width: 1199px) {
        max-width: 45%;
    }

    @media (max-width: 767px) {
        max-width: 100%;
    }

    h3 {
        font-size: 24px;
        color: #623e2a;
        font-family: GothicA1_Med;

        @media (max-width: 1199px) {
            font-size: 20px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
            font-size: 18px;
        }

        @media (max-width: 480px) {
            font-size: 18px;
        }
    }

    h1 {
        color: #fff;
        font-size: 58px;
        font-family: NotoSerifDisplay_Med;

        @media (max-width: 1199px) {
            font-size: 36px;
        }

        @media (min-width: 768px) and (max-width: 991px) {
            font-size: 30px;
        }

        @media (max-width: 480px) {
            font-size: 30px;
        }
    }

    p {
        color: #fff;
        font-size: 18px;
        font-family: Inter_Reg;

        @media (min-width: 768px) and (max-width: 991px) {
            font-size: 16px;
        }

        @media (max-width: 480px) {
            font-size: 14px;
        }
    }


}

.mainBanner_wrrp {
    background: url("../../public/assets/images/mainBannerImg.png") no-repeat right center;
    background-size: 760px;

    @media (max-width: 1440px) {
        background-size: 678px;
    }

    @media (max-width: 1300px) {
        background-size: 560px;
    }

    @media (max-width: 1199px) {
        background: none;
    }
}

@media (min-width: 1200px) {
    .mainBanner_img {
        display: none;
    }
}

.mainBanner_img img {
    width: 100%;

    @media (max-width: 767px) {
        max-width: 400px;
    }
}

.Toastify__toast {
    max-height: max-content !important;
    min-height: min-content;
}

// spinner 
.sweet-loading {
    background: #ffffff42;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 999999;
    // left: var(--sideNav-width);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        // right: var(--sideNav-width);
        position: relative;
    }

    img {
        width: 200px;
    }
}

@keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(0.5, 0.5);
    }

    100% {
        transform: scale(1.2, 1.2);
    }
}